


























































































import {Component, Prop, Vue } from 'vue-property-decorator';
import { toDayMonthDateString, toReadableDateFormat, wagesPer } from '@/utilities/filters';
import { SalaryTable } from '@/interfaces/salaryTable';
import { yesNo } from '@/utilities/filters';
import { PeriodiekVerhoging } from '@/interfaces/periodiekVerhoging';

@Component({
  filters: { toReadableDateFormat, wagesPer, yesNo },
  components: {},
})
export default class SalaryTableDetail extends Vue {
  @Prop({ required: true }) private salaryTable!: SalaryTable;
  @Prop({ default: false }) private showInlenersSalaryTableFields!: boolean;
  @Prop({ default: false }) private hourlyWages!: boolean;
  private aardTypesMap = {
    AD: 'Administratief',
    TE: 'Technisch',
    ME: 'Medisch',
    IN: 'Industrieel',
  };
  private advLabelMap = {
    percentage: 'Vast percentage',
    hours_per_week: 'ADV uren per week',
    hours_per_year: 'ADV uren per jaar',
    days_per_year: 'ADV dagen per jaar',
  };
  private getPeriodicals(ageTable: { [payscale: number]: { [periodical: number]: number }}): number[] {
    let periodicals: number[] = [];
    Object.values(ageTable).forEach((payscale) => {
      if (Object.keys(payscale).length > periodicals.length) {
        periodicals = Object.keys(payscale).map((periodical) => parseInt(periodical, 10));
      }
    });
    return periodicals;
  }

  private getCellWage(
    ageTable: { [payscale: string]: { [periodical: number]: number } },
    scaleKey: string,
    periodical: number,
    wagesDividedBy: number,
    ) {
    if (!ageTable[scaleKey][periodical]) {
      return '';
    }
    const monthWage = ageTable[scaleKey][periodical].toString().replace(/\.0+$/, '');
    if (!this.hourlyWages) {
      return monthWage;
    }
    // Round cents up.
    const hourlyWage = Math.ceil((parseFloat(monthWage) / wagesDividedBy) * 100) / 100;
    return hourlyWage.toFixed(2);
  }

  private sortPayscales(payscales: string[]) {
    if (payscales.every((value) => value.match(/^\d+$/))) {
      return [...payscales].sort((a, b) => parseInt(a, 10) > parseInt(b, 10) ? 1 : -1);
    }
    return [...payscales].sort();
  }
  private toDisplayObject(verhoging: PeriodiekVerhoging) {
  const dateLabels: { [key: string]: string } = {
    months: 'maanden',
    hours: 'uren',
    weeks: 'weken',
  };
  const measurementLabels: { [key: string]: string } = {
    cumulative: 'cumulatief',
    calendar: 'doorlooptijd',
  };
  const interactionLabels: { [key: string]: string } = {
    both: 'Minimumtijd EN datum',
    any: 'Minimumtijd OF datum',
    trigger_date: 'Vaste datum',
    required_time: 'Minimumtijd',
  };

  return {
    type:
      verhoging.type === 'max_salary_table' ? 'Maximumtijd loontabel' : 'Trede',
    verplicht: verhoging.type === 'optional_periodical' ? 'Nee' : 'Ja',
    datum: verhoging.trigger_date
      ? toDayMonthDateString(verhoging.trigger_date)
      : '-',
    minimumtijd:
      verhoging.required_time > 0
        ? `${verhoging.required_time} ${
            dateLabels[verhoging.required_time_type]
          }`
        : '-',
    meetmethode:
      verhoging.required_time > 0
        ? measurementLabels[verhoging.measurement_type]
        : '-',
    interactie: interactionLabels[verhoging.date_required_time_interaction],
  };
}
}
