







































































































































































import {Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  CaoComplianceDetailAction,
  CaoComplianceDetail as CaoComplianceDetailType,
  InlenerType, AcceptInlenerCaoAction,
} from '@/store/inleners/types';
import {toReadableDateFormat} from '@/utilities/filters';
import SalaryTableDetail from '@/views/Inleners/SalaryTableDetail.vue';
import VButton from '@/components/VButton.vue';
import { GetInlenersCaoPdfAction } from '@/store/main/types';
import getInlenersCaoTemplate from '@/pdf-templates/inlenerscao';
import { ProfileImageAction } from '@/store/account/types';
import UserInfo from '@/interfaces/userInfo';

@Component({
  filters: { toReadableDateFormat },
  components: { SalaryTableDetail, VButton },
})
export default class CaoComplianceDetail extends Vue {
  @Action('caoComplianceDetail', {namespace: 'inleners'}) private fetchCaoComplianceDetail!: CaoComplianceDetailAction;
  @Action('getInlenersCaoPdf', { namespace: 'main' }) private getInlenersCaoPdf!: GetInlenersCaoPdfAction;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: ProfileImageAction;
  @Action('acceptInlenerCao', { namespace: 'inleners' }) private acceptInlenerCao!: AcceptInlenerCaoAction;
  @Getter('userInfo', { namespace: 'account' }) private userInfo!: UserInfo;

  private subscriptionType = InlenerType.SUBSCRIPTION;
  private loaded = false;
  private makingPdf = false;
  private imageSrc = '';
  private complianceDetail!: CaoComplianceDetailType;
  private hourlyWages = false;
  private accepting = false;

  private created() {
    this.getProfileImage().then((response) => {
      this.imageSrc = response;
    });
    const complianceId = this.$route.params.id;
    this.fetchCaoComplianceDetail(complianceId).then((complianceDetail) => {
      this.complianceDetail = complianceDetail;
      })
      .finally(() => {
        this.loaded = true;
      });
  }

  private makePdf() {
    if (!this.complianceDetail) { return; }
    this.makingPdf = true;
    const html = getInlenersCaoTemplate((this.$refs.details as any).innerHTML, this.imageSrc);
    this.getInlenersCaoPdf({ html, filename: this.complianceDetail.cao.full_name })
    .then((link) => {
      link.click();
    })
    .finally(() => {
      this.makingPdf = false;
    });
  }

  private onClickAcceptInlenersCao() {
    if (!this.complianceDetail) return;
    this.accepting = true;
    this.acceptInlenerCao({
      type: 'inleners_cao_compliance',
      id: this.complianceDetail.id,
    }).then((response) => {
      this.complianceDetail = {
        ...this.complianceDetail,
        acceptation: response,
      };
    }).finally(() => {
      this.accepting = false;
    });
  }
}

